import React from "react";

import SEO from "../components/shared/seo";
import { Container, Row, Col, Image } from "react-bootstrap";

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Container>
      <Row className="mt-5">
        <Col sm={12} className="mt-5">
          <h1>NOT FOUND</h1>
          <h4>
            You just hit a route that doesn&#39;t exist...{" "}
            <span className="text-accent">the sadness.</span>
          </h4>
        </Col>
        <Col sm={12} className="mt-5">
          <div
            style={{
              width: "100%",
              height: 0,
              paddingBottom: "75%",
              position: "relative",
            }}
          >
            <Image
              src="http://giphygifs.s3.amazonaws.com/media/1l7GT4n3CGTzW/giphy.gif"
              fluid
            />
            <p>
              <a href="https://giphy.com/gifs/1l7GT4n3CGTzW">via GIPHY</a>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </>
);

export default NotFoundPage;
